import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import AuditTrailModules from 'src/content/Report/AuditTrail/module';
import PublicApiLogModules from 'src/content/Report/PublicApiLog/module';
import TransactionReportModules from 'src/content/Report/TransactionReport/module';
import RequestDetailsReportModules from 'src/content/Report/RequestDetails/module';

const ReportRoutes = [
  registerModulesWithRouter(AuditTrailModules),
  registerModulesWithRouter(PublicApiLogModules),
  registerModulesWithRouter(TransactionReportModules),
  registerModulesWithRouter(RequestDetailsReportModules)
];

export default ReportRoutes;
