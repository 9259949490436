import isProd from './isProd';

const LOCAL_TESTING_URL = 'http://127.0.0.1:8050/v1';
const LOCAL_STAGE_URL = 'http://127.0.0.1:8051/v1';
const STAGIN_URL = 'https://api-staging.ventel.in/v1';
const PROD_URL = 'https://api.ventel.in/v1';
// const PROD_URL = 'http://52.221.72.81:8050/v1';

// const isDev = true;
// const BASE_URL = 'https://vltd-api.traxsmart.in/v1';

const BASE_URL = isProd ? PROD_URL : STAGIN_URL;
// const BASE_URL = LOCAL_STAGE_URL;
// const BASE_URL = LOCAL_TESTING_URL;
// const BASE_URL = 'https://vltd-api-staging.traxsmart.in/v1';
// const BASE_URL = 'https://ascl-ps.traxsmart.in/api';

// AUTH URLS

export const AUTH_URL = `${BASE_URL}/auth`;

export const LOGIN_URL = `${AUTH_URL}/login`;
export const REFRESH_TOKEN_URL = `${AUTH_URL}/refresh-tokens`;
export const REGISTER_URL = `${AUTH_URL}/register`;
export const LOGOUT_URL = `${AUTH_URL}/logout`;
export const GET_LOGGED_IN_URL = `${AUTH_URL}/logged-in-user`;
export const FORGET_PASSWORD_URL = `${AUTH_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${AUTH_URL}/reset-password`;
export const CHANGE_PASSWORD_URL = `${AUTH_URL}/change-password`;

// APP URLS

// user urls
export const USER_URL = `${BASE_URL}/users`;
export const FILE_URL = `${BASE_URL}/file`;
export const RAW_LOGS = `${BASE_URL}/raw-logs`;
export const ROLE_URL = `${BASE_URL}/roles`;
export const DESIGNATION_URL = `${BASE_URL}/designations`;
export const ORGANISATION_URL = `${BASE_URL}/organization`;
export const PLAN_URL = `${BASE_URL}/plans`;
export const IP_URL = `${BASE_URL}/whitelist/ip`;
export const NUMBERS_URL = `${BASE_URL}/whitelist/numbers`;
export const TICKETS_URL = `${BASE_URL}/tickets`;

export const FIRMWARE_URL = `${BASE_URL}/firmware`;

// survey end points
export const ADMIN_DIVISION_URL = `${BASE_URL}/admin-divisions`;
export const ASSET_URL = `${BASE_URL}/asset`;
export const IDENTIFIER_URL = `${BASE_URL}/identifier`;

export const ADDRESS_URL = `${BASE_URL}/locations`;
export const GEOFENCE_URL = `${BASE_URL}/geofences`;
export const VEHICLE_URL = `${BASE_URL}/vehicle`;
export const DEVICE_URL = `${BASE_URL}/device`;
export const SIM_URL = `${BASE_URL}/sim`;
export const JOB_BATCH = `${BASE_URL}/job-batch`;
export const JOB_BATCH_COUNTS = `${BASE_URL}/job-batch/counts`;
export const VEHICLE_STATUS_URL = `${BASE_URL}/vehicle/statuses`;
export const POSITION_URL = `${BASE_URL}/vehicle/position/track`;
export const ROUTE_URL = `${BASE_URL}/routes`;
export const CERTIFICATE_URL = `${BASE_URL}/certificate`;
export const REPORT_ALERT = `${BASE_URL}/report/alert`;
export const VEHICLE_TIMELINE_URL = `${BASE_URL}/vehicle/trips/timeline`;
export const VEHICLE_GEOFENCE_ASSOCIATION_URL = `${BASE_URL}/association`;
export const REPORT_URL = `${BASE_URL}/report`;
export const JOB_URL = `${BASE_URL}/job`;
export const API_LOG_URL = `${BASE_URL}/api-log`;
export const CHECKPOINT_URL = `${BASE_URL}/checkpoint`;
export const SEARCH_URL = `${BASE_URL}/search`;
export const MNOJOB_URL = `${BASE_URL}/mno-job`;
// Item
export const ITEM_URL = `${BASE_URL}/item`;
export const ACTIVATION_URL = `${BASE_URL}/activation`;
// wallet
export const WALLET_URL = `${BASE_URL}/wallet`;
