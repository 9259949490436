import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const RequestDetailsReportModule = new Module({
  id: 'request-details-report',
  name: 'Request Details',
  path: 'request-details',
  Component: ComponentLoader(
    lazy(() => import('src/content/Report/RequestDetails/View'))
  ),
});

const RequestDetailsReportModules = [RequestDetailsReportModule];

export default new ModuleBundle('RequestDetailsReport', RequestDetailsReportModules);
